<template>
  <div>
    <Toolbar :handle-reset="resetForm" :handle-submit="onCreatePage" />
    <PageForm
      :errors="violations"
      :values="item"
      ref="createForm"
      refForm="createForm"
      :parent="parent"
      :loading="isLoading"
    />
    <Loading :visible="isLoading" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { createHelpers } from "vuex-map-fields";
import PageForm from "../../components/page/Form.vue";
import Loading from "../../components/Loading";
import Toolbar from "../../components/Toolbar";
import CreateMixin from "../../mixins/CreateMixin";
import { mapGetters } from "vuex";

const servicePrefix = "Page";

const { mapFields } = createHelpers({
  getterType: "page/getField",
  mutationType: "page/updateField",
});

export default {
  name: "PageCreate",
  servicePrefix,
  mixins: [CreateMixin],
  components: {
    Loading,
    Toolbar,
    PageForm,
  },
  data() {
    return {
      item: {},
      parent: {},
    };
  },
  computed: {
    ...mapFields(["error", "isLoading", "created", "deleted", "violations"]),
    ...mapGetters("page", ["find"]),
  },
  methods: {
    ...mapActions("page", {
      create: "create",
    }),
    onCreatePage() {
      if (this.$route.params.parent) {
        this.$refs.createForm.item.parent = this.$route.params.parent;
      }
      this.onSendForm();
    },
  },
  created() {
    this.parent = this.$route.params.parent
      ? this.find(this.$route.params.parent)
      : {};
  },
};
</script>
